<template>
  <div>
    <div class="row">
      <h2 class="col-sm-12 col-md-6 col-lg-8">{{title}}</h2>
      <div class="col-sm-12 col-md-6 col-lg-4 container-info push-right">
        <field-input :value.sync="search" placeholder="Buscar" :clearable="true"/>
      </div>
    </div>
    <div class="row">
      <div v-for="(card, index) in searching(cards)" :key="index" class="col-sm-12 col-md-4 col-lg-4 mb-2">
        <b-card class="cardx my-card " @mouseover="onHover(card.id)" @mouseleave="onHover('')" style="position: relative;">
            <div class="row">
              <div class="col-sm-8 my-card-content">
                <h5 class="custom-title">{{$t(card.title)}}</h5>
                <p class="card-text mt-2">{{card.description}}</p>
              </div>
              <div class="col-sm-4 my-card-icon horizontal-center">
              <font-awesome-icon :icon="['fas', card.icon]" size="5x" class="push-right warning" style="color: #f18805;"/>
              </div>
            </div>
          <div slot="footer">
            <b-button @click="selectCard(card)" variant="outline-warning">{{textButton || 'Ver opciones'}}</b-button>
          </div>
        </b-card>
      </div>
      <div class="col-sm-12">
        <b-alert v-if="search !== '' && searching(cards).length === 0" class="col-sm-12" :show="!!search" variant="warning">
          <div class="alert-body">
            <span>No se encontraron coincidencias para la búsqueda <b>{{search}}</b></span>
          </div>
        </b-alert>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'select-category',
  props: ['title', 'cards', 'selected', 'textButton'],
  data () {
    return {
      search: '',
      hover: null,
      mySelected: undefined
    }
  },
  watch: {
    selected () {
      this.mySelected = this.selected
      this.$emit('update:selected', this.selected)
    }
  },
  methods: {
    selectCard (card) {
      this.mySelected = {...card, ts: (new Date()).getTime()}
      this.$emit('update:selected', this.mySelected)
    },
    searching (cards) {
      if (this.search === '') return cards
      else return cards.filter(card => {
        return (card.title.toLowerCase()).includes(this.search.toLowerCase()) || (card.description.toLowerCase()).includes(this.search.toLowerCase())
      })
    },
    onHover (id) {
      this.hover = id
    }
  }
}
</script>
<style lang="scss">

.cardx.my-card:hover{
  border: 1px solid var(--warning-5);
  background: white;
}
.custom-title{
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-card{
  margin-bottom: 0;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border: 1px solid white;
  box-shadow: none !important;
  border: 1px solid #dedede;
  .b-card__collapsible-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: inherit;
    .b-card__footer{
      margin-top: auto;
    }
  }
  .my-card-content{
    width: calc(100% - 90px - 2rem);
  }
  .my-card-icon{
    width: calc(90px + 2rem);
  }
  &.card .card-footer {
    border-top: none !important;
    background-color: transparent;
    padding-top: 0 !important;
  }
}
</style>
