<template>
  <div class="row" id="form" :class="origin === 'rule' || origin === 'rules' ? '' : ''" v-if="viewData">
    <div class="col-sm-12 col-md-5 container-info" :class="origin === 'rule' || origin === 'rules' ? '' : 'pl-01 pb-2'">
      <label>Variable</label>
      <b-card  v-if="categorySelected && item" class="cardx my-card" style="position: relative; height: fit-content !important;">
        <div class="row">
          <div class="col-sm-6 my-card-content">
            <h5 class="custom-title" :alt="categorySelected.title || ''">{{categorySelected.title || ''}}</h5>
            <p class="card-text mt-1 mb-2">{{categorySelected.description}}</p>
            <h5 class="custom-title" :alt="item.title || ''">{{item.title || ''}}</h5>
            <p class="card-text mt-1">{{item.description}}</p>
          </div>
          <div class="col-sm-6 my-card-icon horizontal-center">
            <!-- <feather-icon :icon="item.icon" size="5x" class="push-right warning"/> -->
            <font-awesome-icon :icon="['fas', item.icon]" size="5x" class="push-right warning" style="color: #f18805;"/>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-7" :class="origin === 'rule' || origin === 'rules' ? '' : 'pr-0'">
      <form-render v-show="!loading.form" :containerButtonsClass="'col-sm-12 push-right'" :class="nullable && nullable.length > 0 && nullable[0].id? 'only-null' : ''"
        :key="keyFormRender"
        :fields="fields"
        :form.sync="form"
        ref="formRenderFormGroup"
        @send="onAccept">
        <div slot="variable-info">
          <h6  v-if="item">Valores de la variable</h6>
        </div>
        <div slot="nullable" class="pb-2" v-if="fields.length > 0">
          <field-checkbox :value.sync="nullable" :options="[{id: true, text: 'Solo nulos o vacíos'}]"></field-checkbox>
        </div>
        <div slot="addMultiple" class="horizontal-center">
          <b-button variant="success" @click="addMultiple"><feather-icon icon="PlusIcon" size="1x"/> Agregar</b-button>
        </div>
        <div v-for="index of totalInputs" :key="index" :slot="`removeInput_${index - 1}`" class="push-right">
          <feather-icon v-if="totalActiveInputs > 1" @click="() => removeInput(index - 1)" class="text-danger cursor-pointer" icon="TimesIcon" size="1x"/>
        </div>
        <div slot="splitted" class="push-right pb-3" v-if="chips.length > 0 && chips[0] !== ''">
          <b-badge pill v-for="(chip, index) in chips" :key="index" variant="primary" closable @click="removeChip(chip)">{{chip}}</b-badge>
        </div>
      </form-render>
      <div v-show="loading.form">
        <b-skeleton width="60%"/>
        <b-skeleton type="input" width="30%" class="mt-1"/>
        <b-skeleton type="input" width="60%" class="mt-1"/>
        <b-skeleton type="input" width="40%" class="mt-1"/>
        <b-skeleton type="input" width="50%" class="mt-1"/>
        <b-skeleton type="input" width="80%" class="mt-1"/>
      </div>
    </div>
  </div>
</template>
<script>
import relationTypeItems from '@/const-data/groups/relation-type-item'
// import cards from '@/const-data/groups/cards'
import comunas from '@/const-data/comunas.json'

import TicketManagementService from '../../ticketManagement.service.js'
import SelectCategory from './SelectCategory.vue'
export default {
  props: ['item', 'data', 'send', 'modified', 'origin'],
  data () {
    return {
      buttonSend: {text: 'Crear grupo', icon: '', color: 'warning', class: 'push-right mb-4'},
      groupField: [
        {fieldType: 'FieldInput', name: 'group_name', label: 'Nombre grupo', containerClass: 'container-info col-sm-12'},
        {fieldType: 'FieldTextarea', placeholder: '', name: 'group_desc', label: 'Descripción del grupo', containerClass: 'container-info col-sm-12', validation: 'required', maxLength: 1000},
        {name: 'variable-info', useSlot: true, containerClass: 'col-sm-12'}
      ],
      fields: [],
      form: {},
      originalForm: {},
      keyFormRender: 0,
      totalInputs: 1,
      totalActiveInputs: 1,
      chips: [],
      typeofLoadedData: '',
      innerData: {},
      mainClassField: 'container-info col-sm-12',
      nullable: [{id: false}],
      categorySelected: {},
      loading: {
        form: false,
        local: false
      },
      ticketManagementService: new TicketManagementService(this),
      myModified: undefined,
      cards: [],
      viewData: false
    }
  },
  watch: {
    modified () {
      this.myModified = this.modified
    },
    myModified () {
      this.$emit('update:modified', this.myModified)
    },
    form: {
      handler (form, prevForm) {
        this.hasBeenModified(form, prevForm)
        form = this.createChips(form)
        return form
      },
      deep: true
    },
    nullable () {
      // Re-renderiza parte del formulario dependiendo si solo acepta campos nulos o no
      if (!!this.nullable && this.nullable.length > 0 && this.nullable[0].id) {
        this.myModified = this.typeofLoadedData !== 'nullable'
        this.innerData.typeof_value = 'nullable'
        this.fields.splice(this.origin === 'rule' ? 2 : 4)
      } else {
        this.myModified = this.typeofLoadedData === 'nullable'
        this.innerData.name = this.form.group_name
        this.innerData.typeof_value = ''
        // this.nullable = false
        this.addFields()
        this.setValuesForm(this.innerData)
        if (this.item.type === 'list') this.getDataList(this.item, 'nullable')
      }
      this.keyFormRender++
    }
  },
  beforeMount () {
    this.addFields()
    this.getConditions()
    // // Si no hay data, puede cargarlo de inmediato
    // if ((!this.data || !this.data.id) && this.item && this.item.type === 'list') this.getDataList(this.item, 'beforeMount')
  },
  mounted () {
    this.setValuesForm(this.data)
    // this.getInfoVariable()
    if (this.origin === 'rule') this.buttonSend.text = 'Aceptar'
    if (this.data && this.data.id && this.item.type === 'list' && this.data.typeof_value !== 'nullable') this.getDataList(this.item, 'mounted')
  },
  methods: {
    getConditions() {
      const arrServices = [
        {name: 'getConditions'}
      ]
      this.ticketManagementService.callMultipleServices(arrServices, true)
        .then(response => {
          this.cards = response.getConditions.data
          if (this.item && this.item.parent) {
            this.categorySelected = this.cards.filter((element) => element.id === this.item.parent)[0]
          }
          if ((!this.data || !this.data.id) && this.item && this.item.type === 'list') this.getDataList(this.item, 'beforeMount')
          this.viewData = true
        })
        .catch(err => {
          console.error(err)
        })
    },
    callSubmitFormRender (e) {
      this.$refs.formRenderFormGroup.checkForm()
    },
    // getInfoVariable () {
    //   if (this.item && this.item.parent) {
    //     this.categorySelected = this.cards.filter(({id}) => id === this.item.parent)[0]
    //   }
    // },
    setValuesForm (data) {
      // Asigna todos los valores del grupo previamente existente a los campos correspondientes
      // La función "addFields" debe haberse ejecutado antes de hacer esta asignación
      if (!!data && !!data.id) {
        this.innerData = {...data}
        this.typeofLoadedData = data.typeof_value
        this.buttonSend.text = 'Actualizar'
        this.buttonSend.color = 'outline-warning'
        const form = {
          group_name: data.name,
          group_desc: data.description
        }
        
        if (data.typeof_value === 'nullable') {
          this.nullable = [{id: true}]
        } else if (['text', 'text-large'].includes(this.item.type)) {
          data.values.map((value, index) => {
            form[`${data.variable}_${index}`] = value
            if (index > 0) this.addMultiple()
          })
        } else if (['number-splitted', 'text-splitted'].includes(this.item.type)) {
          form[data.variable] = data.values.join(',')
        } else if (this.item.type === 'date') {
          const from = data.values.from.split('-')
          const to = data.values.to.split('-')
          form[`${data.variable}`] = {
            start: new Date(from[0], parseInt(from[1]) - 1, from[2]),
            end: new Date(to[0], parseInt(to[1]) - 1, to[2])
          }
        } else if (this.item.type === 'number-range') {
          form[`${data.variable}_from`] = data.values.from
          form[`${data.variable}_check_from`] = !!data.values.include_from ? [{id: 'true'}] : []
          form[`${data.variable}_to`] = data.values.to
          form[`${data.variable}_check_to`] = !!data.values.include_to ? [{id: 'true'}] : []
        } else if (this.item.type === 'number') {
          form[`${data.variable}`] = data.values[0]
        }
        this.form = {...form}
        this.myModified = false
        // Variable "ini" es indispensable para saber cuando el form sea modificado
        this.form['ini'] = true
        this.keyFormRender++
      }
    },
    addFields () {
      // Identifica el tipo de variable y añade los campos y/o botones en consecuencia
      if (!this.item) {
        this.errorMsg = 'Debe seleccionar un ítem'
      } else {
        let newFields = []
        if (['text', 'text-large'].includes(this.item.type)) {
          this.totalInputs = 1
          this.totalActiveInputs = 1
          if (this.item.type === 'text-large') {
            newFields = [{fieldType: 'FieldTextarea', focusable: true, name: `${this.item.id}_0`, placeholder: '', containerClass: `${this.mainClassField} textarea-multiple`, validation: 'required', maxLength: 1000}]
          } else {
            newFields = [{fieldType: 'FieldInput', type: this.item.type, name: `${this.item.id}_0`, containerClass: `${this.mainClassField} input-multiple`, validation: 'required'}]
          }
          newFields = newFields.concat([
            {name: 'removeInput_0', useSlot: true, containerClass: 'remove-button'},
            {name: 'addMultiple', useSlot: true, containerClass: 'col-sm-12'}
          ])

        } else if (['number-splitted', 'text-splitted'].includes(this.item.type)) {

          newFields = [
            {fieldType: 'FieldTextarea', placeholder: 'Ingrese los valores separados por coma', name: this.item.id, containerClass: this.mainClassField, validation: 'required'},
            {name: 'splitted', useSlot: true, containerClass: 'col-sm-12'}
          ]

        } else if (this.item.type === 'number-range') {

          newFields = [
            {fieldType: 'FieldCheckbox', multiple: true, name: `${this.item.id}_check_from`, info: 'Incluir', containerClass: 'col-sm-12', options: [{id: 'true', text: 'Incluir desde (Mayor o igual)'}]},
            {fieldType: 'FieldInput', type: 'number', name: `${this.item.id}_from`, label: 'Desde', containerClass: this.mainClassField, validation: 'required'},
            {fieldType: 'FieldCheckbox', multiple: true, name: `${this.item.id}_check_to`, info: 'Incluir', containerClass: 'col-sm-12', options: [{id: 'true', text: 'Incluir hasta (Menor o igual)'}]},
            {fieldType: 'FieldInput', type: 'number', name: `${this.item.id}_to`, label: 'Hasta', containerClass: this.mainClassField, validation: 'required'}
          ]
          this.form[`${this.item.id}_check_from`] = [{id: 'true'}]
          this.form[`${this.item.id}_check_to`] = [{id: 'true'}]

        } else if (this.item.type === 'date') {

          newFields = [
            {fieldType: 'FieldDatepicker', name: this.item.id, range: true, containerClass: this.mainClassField, validation: 'required'}
          ]

        } else if (this.item.type === 'number') {
          newFields = [
            {fieldType: 'FieldInput', name: this.item.id, type: 'number', label: this.item.title, containerClass: this.mainClassField, validation: 'required'}
          ]

        } else if (this.item.type === 'list') {
          newFields = [
            {fieldType: 'FieldSelect', name: this.item.id, multiple: true, containerClass: this.mainClassField, validation: 'required', searchOnType: { nChars: 999},
              options: []
            }
          ]
        }
        this.chips = []
        newFields = newFields.map(field => ({...field, placeholder: field.placeholder || '', containerClass: field.containerClass + (relationTypeItems[this.item.type].nullable ? ' hide-on-null' : '')}))
        if (relationTypeItems[this.item.type].nullable) {
          newFields = [{name: 'nullable', useSlot: true, containerClass: 'col-sm-12'}].concat(newFields)
        }
        const initFields = [...this.groupField]
        if (this.origin === 'rule') {
          initFields.splice(0, 2)
        }
        this.fields = initFields.concat(newFields)
        this.keyFormRender++
      }
    },
    addMultiple () {
      // Agrega un field dependiendo del tipo de variable y su respectivo botón de eliminación
      this.totalInputs++
      this.totalActiveInputs++
      let field = {}
      if (this.item.type === 'text-large') {
        field = {fieldType: 'FieldTextarea', name: `${this.item.id}_${this.totalInputs - 1}`, placeholder: '', containerClass: `${this.mainClassField} textarea-multiple`, validation: 'required', maxLength: 1000}
      } else {
        field = {fieldType: 'FieldInput', type: this.item.type, name: `${this.item.id}_${this.totalInputs - 1}`, containerClass: `${this.mainClassField} input-multiple`, validation: 'required'}
      }
      field = {...field, placeholder: '', containerClass: field.containerClass + (relationTypeItems[this.item.type].nullable ? ' hide-on-null' : '')}
      this.fields.splice(this.fields.findIndex(({name}) => name === 'addMultiple'), 0,
        {...field},
        {name: `removeInput_${this.totalInputs - 1}`, useSlot: true, containerClass: `remove-button ${relationTypeItems[this.item.type].nullable ? ' hide-on-null' : ''}`}
      )
      this.setInputWidth()
      this.keyFormRender++
    },
    removeInput (index) {
      // Elimina field asociado y limpia** el atributo del form
      // **No lo elimina del todo, pero el validador en FormRender no entrega el valor que no esté en Fields
      const currentForm = {...this.form}
      this.fields.findIndex(({name}) => name === `${this.item.id}_${index}`)
      this.fields.splice(this.fields.findIndex(({name}) => name === `${this.item.id}_${index}`), 2) // 2 = Field + remove
      this.totalActiveInputs--
      delete this.form[`${this.item.id}_${index}`]
      this.setInputWidth()
      setTimeout(() => {
        Object.keys(currentForm).map(key => {
          if (this.fields.findIndex(({name}) => name === key) !== -1) this.form[key] = currentForm[key]
        })
      }, 0)

    },
    setInputWidth () {
      // Setea clases en fields para posicionar boton de eliminar field
      this.fields = this.fields.map(field => {
        const extraClass = field.containerClass && (field.containerClass.includes('input-multiple') || field.containerClass.includes('textarea-multiple')) && this.totalActiveInputs > 1 ? ' active' : ''
        return ({...field, containerClass: field.containerClass ? field.containerClass.replace('active', '') + extraClass : extraClass})
      })
    },
    createChips (form) {
      // Crea los chips cuando ingresa datos en elemento de separación por comas
      if (this.item && form[this.item.id]) {
        switch (this.item.type) {
        case 'number-splitted':
          form[this.item.id] = this.replaceNumberSplitted(form[this.item.id])
          this.chips = form[this.item.id].split(',')
          break
        case 'text-splitted':
          form[this.item.id] = this.replaceTextSplitted(form[this.item.id])
          this.chips = form[this.item.id].split(',')
          break
        }
      }
      return form
    },
    hasBeenModified (form, prevForm) {
      // En caso de existir datos existentes (atributo "ini" dentro del form), valida si ha sido modificado
      if (prevForm.ini && form.ini) {
        // Evalúa en ambas direcciones en caso de que en el original existieran datos adicionales o si en el actual los hay
        this.myModified = this.isDifferentForm(this.form, this.originalForm)
        this.myModified &= this.isDifferentForm(this.originalForm, this.form)
      } else if (!prevForm.ini && form.ini) {
        this.originalForm = {...form}
      }
    },
    isDifferentForm (form1, form2) {
      return Object.keys(form1).filter(key => {
        if (Array.isArray(form1[key])) {
          const ids_1 = form1[key].map(val => val.id).sort()
          if (Array.isArray(form2[key])) {
            const ids_2 = form2[key].map(val => val.id).sort()
            return JSON.stringify(ids_1) !== JSON.stringify(ids_2)
          } else {
            return true
          }
        } else {
          return form1[key] !== form2[key]
        }
      }).length !== 0
    },
    replaceNumberSplitted (element) {
      // Reemplaza todo lo que no sea números o comas y limpia valores identicos duplicados
      let myArray = (element.replaceAll(/[^0-9,]/g, '')).split(',')
      myArray = myArray.filter((element, index) => element !== '' || index === myArray.length - 1)
      if (myArray[myArray.length - 1] === '') myArray = [...new Set(myArray)]
      return myArray.join(',')
    },
    replaceTextSplitted (element) {
      // Limpia valores identicos duplicados
      let myArray = element.split(',')
      myArray = myArray.filter((element, index) => element !== '' || index === myArray.length - 1)
      if (myArray[myArray.length - 1] === '') myArray = [...new Set(myArray)]
      return myArray.join(',')
    },
    removeChip (chip) {
      // Elimina texto del textarea y componente chip
      this.form[this.item.id] = this.form[this.item.id].split(',').filter(current => current !== chip).join(',')
      this.form[this.item.id] = this.replaceNumberSplitted(this.form[this.item.id])
      this.chips = this.form[this.item.id].split(',')
    },
    getDataList (item, origin) {
      // Obtiene los campos desde la función guardada en el atributo "fx" de la variable
      const index = this.fields.findIndex(({name}) => name === item.id)
      this.fields[index].loading = true
      if (item.fx) {
        this.loading.local = true
        this.ticketManagementService.callService(item.fx, {limit:9999, page:1})
          .then(response => {
            if (index !== -1) { // Ocurre cuando se está cargando una lista con tipo nulo
              this.fields[index].options = this.ticketManagementService.formatSelect(response.data, item.id)
              this.fields[index].loading = true
              this.keyFormRender++
              if (this.data && this.data.id) {
                this.form[this.data.variable] = this.fields[index].options.filter(opt => this.data.values.findIndex(val => val === opt.id) !== -1)
              }
            }
          })
          .catch(err => {
            console.error(err)
            // this.$alert(`Ocurrió un problema al cargar la lista de ${item.title.toLowerCase()}`)
            this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
          })
          .finally(end => {
            this.loading.local = false
          })
      } else if (item.data) {
        if (index !== -1) { // Ocurre cuando se está cargando una lista con tipo nulo
          this.fields[index].options = item.data
          if (this.data && this.data.id) {
            this.form[this.data.variable] = this.fields[index].options.filter(opt => this.data.values.findIndex(val => val === opt.id) !== -1)
          }
        }
        this.keyFormRender++
      } else if (['commune', 'commune_drop'].includes(item.id)) {
        if (index !== -1) { // Ocurre cuando se está cargando una lista con tipo nulo
          this.fields[index].options = this.ticketManagementService.formatSelect(comunas, item.id)
          if (this.data && this.data.id) {
            this.form[this.data.variable] = this.fields[index].options.filter(opt => this.data.values.findIndex(val => val === opt.id) !== -1)
          }
        }
        this.keyFormRender++
      } else {
        this.$alert(this.$t('msg-problema-variable'))
      }
    },
    getValues (form, item) {
      // Formatea los valores ingresados en el formulario según tipo y selección
      let values = null
      if (!this.nullable || this.nullable.length === 0 || (this.nullable.length > 0 && !this.nullable[0].id)) {
        switch (item.type) {
        case 'number-splitted':
          return [...new Set(this.replaceNumberSplitted(form[item.id]).split(','))]
        case 'text-splitted':
          return [...new Set(this.replaceTextSplitted(form[item.id]).split(','))]
        case 'number-range':
          if (form[`${item.id}_from`] > form[`${item.id}_to`]) {
            [form[`${item.id}_from`], form[`${item.id}_to`]] = [form[`${item.id}_to`], form[`${item.id}_from`]]
          }
          return {
            from: parseInt(form[`${item.id}_from`]),
            to: parseInt(form[`${item.id}_to`]),
            include_from: form[`${item.id}_check_from`].length === 1,
            include_to: form[`${item.id}_check_to`].length === 1
          }
        case 'text':
          values = []
          for (let index = 0; index < this.totalInputs; index++) {
            if (form[`${item.id}_${index}`] && this.fields.findIndex(({name}) => name === `${item.id}_${index}`) !== -1) values.push(form[`${item.id}_${index}`])
          }
          return [...new Set(values)]
        case 'text-large':
          values = []
          for (let index = 0; index < this.totalInputs; index++) {
            if (form[`${item.id}_${index}`]) values.push(form[`${item.id}_${index}`])
          }
          return [...new Set(values)]
        case 'list':
          return [...new Set(form[item.id].map(element => element.id))]
        case 'date':
          return {
            from: this.$options.filters.moment(form[item.id].start, 'YYYY-MM-DD'),
            to: this.$options.filters.moment(form[item.id].end, 'YYYY-MM-DD')
          }
        case 'number':
          return [form[`${item.id}`]]
        }
      } else {
        values = ['number-splitted', 'text-splitted', 'text', 'text-large', 'list'].includes(item.type) ? [] : {from: null, to: null}
      }
      return values
    },
    onAccept (form) {
      this.loading.form = true
      this.$emit('send', {...this.form, values: this.getValues(form, this.item)})
    }
  }
}
</script>
<style lang="scss">
.only-null > div{
  transition: height 0.5s ease !important;
  &.hide-on-null{
    opacity: 0 !important;
    height: 0px;
    position: absolute;
  }
}

</style>
