export default {
  'number-splitted': {
    type: 'number',
    text: 'Numérico. Ingreso separado por coma.',
    nullable: true
  },
  'text-splitted': {
    type: 'number',
    text: 'Numérico. Ingreso separado por coma.',
    nullable: true
  },
  'number-range': {
    type: 'range',
    text: 'Rango numérico',
    nullable: true
  },
  'date': {
    type: 'range',
    text: 'Rango de fechas',
    nullable: true
  },
  'text': {
    type: 'text',
    text: 'Texto',
    nullable: true
  },
  'number': {
    type: 'number',
    text: 'Número único',
    nullable: false
  },
  'text-large': {
    type: 'text',
    text: 'Texto largo',
    nullable: true
  },
  'list': {
    type: 'list',
    text: 'Lista',
    nullable: true
  }
}